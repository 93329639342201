@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* This prevents horizontal scroll */
}

/* For NavBar */

.underline-animation::after {
  content: "";
  display: block;
  width: 0;
  height: 2px; /* Adjust the height of the underline as needed */
  background: goldenrod;
  transition: width 0.3s;
}

.underline-animation:hover::after {
  width: 100%;
}

/********************/

/* For slideShow */

.carousel-image {
  max-height: 500px; /* Adjust as needed */
  width: auto;
  margin: auto; /* This centers the image in the Carousel */
}

/* Add this to your CSS file */
.carousel-item {
  height: 500px; /* Set the height you want for the slideshow */
}

.carousel-item img {
  height: 100%;
  object-fit: cover; /*  cover This will cover the area without stretching the image */
  width: 100vw;
  max-width: none;
}

.carousel-item img {
  /* Your existing styles */
  height: 100%;
  object-fit: cover; /*  cover This will cover the area without stretching the image */
  width: 100vw;
  max-width: none;
}

/********************/

.center-bold-gold {
  text-align: center;
  font-weight: bold;
  color: goldenrod;
  font-family: "Courier New", Courier, monospace;
  background-color: black;
  padding: 1ex;
  margin: 0px;
}

/* For the Youtube videos section */

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  max-width: 100% !important;
  height: auto !important;
  overflow: auto !important;
  margin-bottom: 24px !important;
}

.css-16vq84g-MuiPaper-root-MuiCard-root {
  border: 2px solid rgb(43, 42, 42) !important; /* Border style and color */
  border-radius: 10px !important;
}

.css-lgk7oi {
  margin-bottom: 2ex;
  /* background: linear-gradient(to bottom, #000000, #4b5563, #000000); */
}

.css-h93ljk-MuiTypography-root {
  font-weight: bold !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.css-qhibcd-MuiContainer-root {
  background: linear-gradient(to bottom, #000000, #4b5563, #000000);
  padding-bottom: 1ex !important;
  padding-top: 1ex !important;
}

.css-lgk7oi {
  width: fit-content;
}

.background {
  background: linear-gradient(to bottom, #000000, #4b5563, #000000);
}
/********************************/

/*********************** For the Services ***********************/

.css-1l5bmg7-MuiPaper-root-MuiCard-root {
  max-width: fit-content !important;
  height: 100% !important;
  border: 2px solid rgb(43, 42, 42) !important; /* Border style and color */
  border-radius: 10px !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: rgb(248, 240, 240) !important;
  border: 8px solid black !important; /* Border style and color */
  background-color: rgb(17, 10, 10) !important;
  padding: 0ex !important;
  padding-left: 1ex !important;
  padding-right: 1ex !important;
}

/*********** For Vercel ************/

.css-u30lmt {
  color: black !important;
  font-size: 15px !important;
}

.css-t1nuxs {
  font-weight: bold !important;
}

.css-156qwxv {
  padding-top: 1ex !important;
  padding-bottom: 1ex !important;
}

/******************************************************/

/****** For Social Media ****/

.list {
  padding-left: 0% !important;
  margin-bottom: 0% !important;
}

/** For Footer *****/
.myBackground {
  background-color: black !important;
}

/** For Projects *****/
.accordion {
  width: 80%;
  margin: 0 auto;
  /* border: 2px solid red;; */
}

.accordion-title {
  cursor: pointer;
  padding: 10px;
  /* background-color: #f0f0f0; */
  /* border: 1px solid blue; */
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  font-weight: 500;
  font-size: medium;
}

.accordion-content {
  padding: 10px;
  /* border: 1px solid #ddd; */
  border-top: 1px solid black;
  display: flex;
  /* justify-content:flex-start; */
  flex-direction: column;
  color: #000;
  font-size: 15px;
}

.accordion-item {
  margin-bottom: 0px;
  margin: 0 !important;
  /* border: 2px solid blue; */
}

.toggle {
  font-weight: bold;
  color: #000000;
  font-size: larger;
}

.projects {
  background: linear-gradient(to bottom, #000000, #4b5563, #000000);
  padding: 0;
  margin: 0;
}

.paragContent {
  /* border: 2px solid red; */
  margin-bottom: 0;
}
